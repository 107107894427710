import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container as BlockContainer } from '../components/Blocks'
//import { Hero, Career, CoreValues } from '../components/Sections'
import { Hero, CoreValues } from '../components/Sections'
import { Container, Row, Col } from 'reactstrap'
import CareerAndJobs from '../components/Sections/CareerAndJobs'

const IndexPage = () => (
  <Layout>
    <SEO title="Career" keywords={[`gatsby`, `application`, `react`]} />
    <Hero src="app/Careers-Cover-Photo.jpg" size={12} height={500} x={90} style={{ marginTop: '60px'}} />
    <BlockContainer padding={0} className="pt-5">
      <Container>
        <Row>
        <Col md="12">
              <h1 className="mb-3">SIDC Career and Jobs</h1>
          </Col>
        </Row>
      </Container>
    </BlockContainer>
    <CareerAndJobs />
    {/* <Career />*/}
    <CoreValues />
    {/* <BlockContainer padding={5} dark id="awards">
      <Container>
          <Row>
          <Col md="12">
              <div className="text-center">
                <h2 className="pb-4 text-dark">Awards</h2>
              </div>
          </Col>
          </Row>
          <Row>
          <Col sm={{order: 2}} md={{ size: 3, order: 1}} className="align-self-center text-md-right text-center">
              <h4 className="text-primary">One</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Two</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Three</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
          </Col>
          <Col sm={{ order: 1 }} md={{ size: 6, order: 2}} className="my-3 d-none d-md-block"> 
              <img className="img-fluid d-block pt-5" alt="SIDC BENEFITS" src={getAssetUrl('app/Awards.png')} />
          </Col>
          <Col sm={{order: 3}} md={{ size: 3, order: 3}} className="align-self-center text-md-left text-center">
              <h4 className="text-primary">One</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Two</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
              <h4 className="text-primary">Three</h4>
              <p className="mb-5">A wonderful serenity has taken possession of my entire soul.</p>
          </Col>
          </Row>
      </Container>
    </BlockContainer> */}
  </Layout>
)

export default IndexPage
